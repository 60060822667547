import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {  Client, NonPaginatedPayoutResponse } from '../../interfaces/components';
import LiquidateForm from '../liquidation/LiquidateForm';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <Button
            borderRadius={'100px'}
            key={i}
            onClick={() => handlePageChange(i)}
            bg={currentPage === i ? '#0FBF95' : 'white'}
            color={currentPage === i ? 'white' : 'black'}
            _hover={{
              bg: currentPage === i ? '#0FBF95' : 'white',
            }}
            mx="1"
          >
            {i}
          </Button>,
        );
      }
    } else {
      [1, 2].forEach((i) => {
        pageNumbers.push(
          <Button
            borderRadius={'100px'}
            key={i}
            onClick={() => handlePageChange(i)}
            bg={currentPage === i ? '#0FBF95' : 'white'}
            color={currentPage === i ? 'white' : 'black'}
            _hover={{
              bg: currentPage === i ? '#0FBF95' : 'white',
            }}
            mx="1"
          >
            {i}
          </Button>,
        );
      });

      if (currentPage > 2 && currentPage < totalPages) {
        pageNumbers.push(<Text key="ellipsis1">...</Text>);
        pageNumbers.push(
          <Button
            borderRadius={'100px'}
            key={currentPage}
            onClick={() => handlePageChange(currentPage)}
            bg="#0FBF95"
            color="white"
            _hover={{
              bg: '#0FBF95',
            }}
            mx="1"
          >
            {currentPage}
          </Button>,
        );
      }

      if (currentPage < totalPages - 1) {
        pageNumbers.push(<Text key="ellipsis2">...</Text>);
      }

      pageNumbers.push(
        <Button
          borderRadius={'100px'}
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          bg={currentPage === totalPages ? '#0FBF95' : 'white'}
          color={currentPage === totalPages ? 'white' : 'black'}
          _hover={{
            bg: currentPage === totalPages ? '#0FBF95' : 'white',
          }}
          mx="1"
        >
          {totalPages}
        </Button>,
      );
    }
    return pageNumbers;
  };

  return (
    <Flex
      alignItems="center"
      justifyContent={['flex-end', 'flex-end', 'center']}
    >
      <Box
        padding={['6px', '6px', '8px', '8px']}
        bg={'#FFF'}
        border={'1px solid #EFEFEF'}
        borderRadius={'44px'}
        display={'flex'}
      >
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          bg="white"
          _hover={{
            background: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        >
          &lt;
        </Button>
        {renderPageNumbers()}
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          bg="white"
          _hover={{
            background: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        >
          &gt;
        </Button>
      </Box>
    </Flex>
  );
};

const ClaimedTokensTable: React.FC<{
  
  payouts:NonPaginatedPayoutResponse | undefined
  //@ts-ignore
}> = ({ payouts }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentClient, setCurrentClient] = useState<Client>({
    name: '',
    id: 0,
  });
  const [unClaimedAmount, setUnclaimedAmount] = useState<number>(0);
  const [payoutId,setPayoutId] = useState<number>(0);
  const [totalClaimed, setTotalClaimed] = useState<number>(0);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number>();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const openRow = (index: number) => {
    if (index === expandedRowIndex) {
      setExpandedRowIndex(undefined);
    } else {
      setExpandedRowIndex(index);
    }
  };

  const totalPages = Math.ceil(
    (payouts?.results.length || 0) / itemsPerPage,
  );
  const filteredResults = payouts?.results.filter((items)=> items.paid && !items.liquidated)
  const paginatedData = filteredResults?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );
  return (
    <>
      <Text
        color={'#1A1A1A'}
        fontSize={['18px', '18px', '18px', '24px']}
        fontWeight={600}
        fontFamily="Montserrat"
      >
        Claimed tokens
      </Text>
      <TableContainer marginTop="10px">
        <Table variant="simple" display={['none', 'none', 'none', 'table']}>
          <Thead>
            <Tr borderRadius="10px" background="#F2F2F2">
              <Th
                fontFamily="Montserrat"
                fontSize="13px"
                fontWeight="700"
                color="#1A1A1A"
                textTransform="capitalize"
                borderRadius="10px 0px 0px 10px"
                width={'24%'}
              >
                Amount
              </Th>
              <Th
                fontFamily="Montserrat"
                fontSize="13px"
                fontWeight="700"
                color="#1A1A1A"
                textTransform="capitalize"
                width={'24%'}
              >
                Tokens Value (USD)
              </Th>
              <Th
                fontFamily="Montserrat"
                fontSize="13px"
                fontWeight="700"
                color="#1A1A1A"
                textTransform="capitalize"
                width={'24%'}
              >
                Client
              </Th>

              <Th
                fontFamily="Montserrat"
                fontSize="13px"
                fontWeight="700"
                color="#1A1A1A"
                textTransform="capitalize"
                justifyContent={'flex-end'}
                width={'28%'}
              >
                <Box display={'flex'} justifyContent={'flex-end'}>
                  Action
                </Box>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData?.map((item, index) => (
              <Tr key={index}>
                <Td
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="500"
                  width={'24%'}
                >
                  {parseInt(item.amount) - (item.tokens_liquidated ) } {item.client.name}
                </Td>
                <Td
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="500"
                  width={'24%'}
                >
                  ${item.dollar_value}
                </Td>
                <Td
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="500"
                  width={'24%'}
                >
                  {item.client.name}
                </Td>

                <Td width={'28%'}>
                  <Box
                    display={'flex'}
                    padding={'0px 12px'}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    gap={'30px'}
                    flex={'1 0 0'}
                  >
                    <Button
                      isDisabled={item.liquidated}
                      style={{
                        display: 'flex',
                        height: '28px',
                        padding: '4px 8px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: '10px',
                        borderRadius: '12px',
                        background: item.liquidated ? '#BFBFBF' : '#0FBF95',

                        color: 'var(--Color-Neutral-50, #FFF)',
                        fontFamily: 'Montserrat',
                        fontSize: '13px',
                        fontWeight: 600,
                        lineHeight: '20px',
                      }}
                      onClick={() => {
                        setIsModalOpen(true);
                        setCurrentClient({
                          name: item.client.name,
                          id: item.client.id,
                        });
                        setUnclaimedAmount(
                          parseInt(item.amount) - (item.tokens_liquidated)
                        );
                        setPayoutId(item.id)
                        setTotalClaimed(parseFloat(item.dollar_value));
                      }}
                    >
                      {item.liquidated ? 'Liquidated' : 'Liquidate'}
                    </Button>
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Table variant="simple" display={['table', 'table', 'table', 'none']}>
          <Thead>
            <Tr
              borderRadius="10px"
              borderBottom="10px solid white"
              background="#F2F2F2"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                padding="0.75rem 1.5rem"
              >
                <Text
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="700"
                  color="#1A1A1A"
                  textTransform="capitalize"
                >
                  Claim Amount
                </Text>
                <Text
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="700"
                  color="#1A1A1A"
                  textTransform="capitalize"
                >
                  Client
                </Text>
              </Box>
            </Tr>
          </Thead>
          <Tbody mt="10px">
            {paginatedData?.map((item, index) => (
              <Tr
                key={index}
                borderBottom={
                  expandedRowIndex !== index
                    ? '1px solid #E2E2E2'
                    : '1px solid white'
                }
              >
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  gap={expandedRowIndex === index ? 4 : 0}
                  padding="16px 12px"
                  borderRadius="10px"
                  bg={expandedRowIndex === index ? '#F2F2F2' : 'white'}
                  borderBottom={
                    expandedRowIndex === index
                      ? '1px solid #E2E2E2'
                      : '1px solid white'
                  }
                  transition="0.2s all"
                  cursor="pointer"
                  onClick={() => openRow(index)}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="500"
                    >
                      {item.amount}
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Text
                        fontFamily="Montserrat"
                        fontSize="13px"
                        fontWeight="500"
                        textAlign="end"
                      >
                        {item.client.name}
                      </Text>
                      <ChevronDownIcon
                        color="#8C8C8C"
                        boxSize={6}
                        transform={
                          expandedRowIndex === index
                            ? 'rotate(180deg)'
                            : 'unset'
                        }
                        transition="0.2s all"
                      />
                    </Box>
                  </Box>

                  <Collapse in={expandedRowIndex === index} animateOpacity>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <Text
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                          color="#8C8C8C"
                        >
                          Tokens Value (USD)
                        </Text>
                        <Text
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                          color="#8C8C8C"
                        >
                          ${item.dollar_value}
                        </Text>
                      </Box>
                    </Box>
                  </Collapse>

                  <Box
                    pt="12px"
                    borderTop={
                      expandedRowIndex === index ? '1px solid  #E2E2E2' : 'none'
                    }
                  >
                    <Button
                      isDisabled={item.liquidated}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen(true);
                      }}
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="500"
                      color={item.liquidated ? '#8C8C8C' : '#0FBF95'}
                      bg={item.liquidated ? '#F2F2F2' : 'white'}
                      border={item.liquidated ? 'none' : '1px solid #0FBF95'}
                      borderRadius="5px"
                      padding="8px 16px"
                      _hover={{
                        bg: item.liquidated ? '#F2F2F2' : '#E6F7F2',
                      }}
                    >
                      {item.liquidated ? 'Liquidated' : 'Liquidate'}
                    </Button>
                  </Box>
                </Box>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      <LiquidateForm
        unClaimedAmount={unClaimedAmount}
        total_claimed={totalClaimed}
        payoutId = {payoutId}
        currentClient={currentClient}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default ClaimedTokensTable;
