export function formatAddress(hexString: string) {
  const prefix = hexString.substring(0, 8); // Get the first 8 characters
  const suffix = hexString.substring(hexString.length - 8); // Get the last 8 characters
  return prefix + '......' + suffix; // Concatenate with dots in between
}

export const formatDateForInput = (dateString: string | number | Date) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toISOString().slice(0, 16); // Format as "YYYY-MM-DDTHH:MM"
};
export const formateDate = (dateString: string ) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString();
};


export function hasDatePassed(dateString:string) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    return inputDate < currentDate;
}