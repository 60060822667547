import { Box, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { NavbarOption } from '../../interfaces/components';
import { useLocation } from 'react-router-dom';
import Header from '../header/Header';
import Drawer from '../drawer/Drawer';

export interface OverlayProps {
  children: React.ReactNode;
}

export const navbarOptions: NavbarOption[] = [
  {
    href: '/',
    key: 'dashboard',
    text: 'Dashboard',
  },
  {
    href: '/investments',
    key: 'investments',
    text: 'Investment tracker',
  },
  {
    href: '/ClientOverview',
    key: 'clients',
    text: 'Clients',
  },
  {
    href: '/upcoming-payouts',
    key: 'payouts',
    text: 'Upcoming payouts',
  },
  {
    href: '/liquidation',
    key: 'liquidation',
    text: 'Liquidation',
  },
];

function Overlay({ children }: OverlayProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [activeView, setActiveView] = useState('payouts');

  useEffect(() => {
    const activeSidebarOption = navbarOptions.find(
      (navbarOption: NavbarOption) => navbarOption.href === location.pathname,
    );

    activeSidebarOption ? setActiveView(activeSidebarOption.key) : setActiveView('');
  }, [location.pathname]);

  return (
    <Box w="100%" h="100vh" display="flex" flexDirection="column" overflow="hidden">
      <Header activeView={activeView} navbarOptions={navbarOptions} openDrawer={onOpen} />

      <Drawer activeView={activeView} navbarOptions={navbarOptions} isOpen={isOpen} onClose={onClose} />

      <Box
        display="flex"
        h={[
          'calc(100vh - 54px)',
          'calc(100vh - 54px)',
          'calc(100vh - 54px)',
          'calc(100vh - 65px)',
          'calc(100vh - 65px)',
        ]}
        background="#F5F5F5"
        flexDirection="column"
      >
        <Box
          mt="20px"
          ml="20px"
          display={['unset', 'unset', 'unset', 'none', 'none']}
          width="fit-content"
          fontFamily="Montserrat"
          fontWeight="600"
          fontSize="18px"
          padding="8px 10px"
          bg="white"
          borderRadius="14px"
          color="#0FBF95"
          alignItems="center"
        >{`/${
          navbarOptions?.find((navbarOption: NavbarOption) => navbarOption.href === location.pathname)?.text
        }`}</Box>
        <Box
          margin="0 auto"
          width="100%"
          height="100%"
          maxWidth="1440px"
          overflowY="auto"
          padding={['5px', '5px', '20px']}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default Overlay;
