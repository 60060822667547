import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image,
  Img,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchClientsInfo } from '../../api/InvestorApis/apis';
import { deleteClientById } from '../../api/InvestorApis/client';
import clientlogo from '../../assets/Clientlogo.png';
import deleteicon from '../../assets/delete.png';
import {
  ClientInfoResponse,
  ClientResultsArray,
} from '../../interfaces/components';
import DeleteClientModal from '../clients/DeleteClientModal';
import ManageClientModal from '../clients/ManageClientModal';

const ClientOverviewTab: React.FC<{
  clientInfo: ClientInfoResponse | undefined;
}> = ({ clientInfo }) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: fetchClientsInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clientInfo'] });
    },
  });
  const {
    isOpen: manageModalOpen,
    onOpen: openManageModal,
    onClose: closeManageModal,
  } = useDisclosure();
  const [clientToEdit, setClientToEdit] = useState<ClientResultsArray | any>(
    null,
  );
  const [clientToDelete, setClientToDelete] = useState<
    ClientResultsArray | any
  >(null);

  const rowsPerPage = 5; // Adjust this value as needed
  const totalPages = Math.ceil((clientInfo?.results.length ?? 0) / rowsPerPage);

  const openRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };
  const onDeleteClick = async (client: ClientResultsArray) => {
    try {
      const res = await deleteClientById(client?.id);
      if (res) {
        toast({
          title: 'Client deleted successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        mutation.mutate();
      }
    } catch (err: any) {
      toast({
        title: 'Something went wrong while deleting client',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.log('Error While Fetch All Users ', err);
    }
  };

  const onEditClick = (client: ClientResultsArray) => {
    setClientToEdit(client);
    openManageModal();
  };

  const paginatedData = clientInfo?.results?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage,
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const items = [];

    const renderPageNumber = (page: number) => (
      <Box
        key={page}
        background={page === currentPage ? '#0FBF95' : 'white'}
        borderRadius="full"
        padding={'0px 6px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Text
          fontWeight="500"
          fontSize="18px"
          lineHeight="20px"
          color={page === currentPage ? 'white' : '#8C8C8C'}
          cursor="pointer"
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Text>
      </Box>
    );

    items.push(renderPageNumber(1));
    if (totalPages > 1) {
      items.push(renderPageNumber(2));
    }

    if (currentPage > 3 && totalPages > 3) {
      items.push(
        <Text key="dots1" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (currentPage > 2 && currentPage < totalPages) {
      items.push(renderPageNumber(currentPage));
    }

    if (currentPage < totalPages - 1 && totalPages > 3) {
      items.push(
        <Text key="dots2" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (totalPages > 2) {
      items.push(renderPageNumber(totalPages));
    }

    return items;
  };

  return (
    <>
      <ManageClientModal
        isOpen={manageModalOpen}
        onClose={() => {
          closeManageModal();
        }}
        onAdded={() => {
          closeManageModal();
          mutation.mutate();
        }}
        clientToEdit={clientToEdit}
      />
      <DeleteClientModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        clientToDelete={clientToDelete}
        onDeleteClick={() => {
          onClose();
          onDeleteClick(clientToDelete);
        }}
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={['24px', '24px', '24px', '21px']}
        background={'rgba(255, 255, 255, 1)'}
        borderRadius={'44px'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
        border={'1px solid rgba(255, 255, 255, 1)'}
        padding={['16px', '16px', '16px', '22px']}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Img src={clientlogo} alt="" height={'36px'} width={'36px'} />
            <Text
              fontSize={'24px'}
              fontWeight={'600'}
              lineHeight={'normal'}
              fontFamily="Montserrat"
            >
              Clients
            </Text>
          </Box>
          <Button
            padding={'10px 14px'}
            borderRadius={'14px'}
            background={'#0FBF95'}
            color={'#FFF'}
            fontFamily="Montserrat"
            fontSize={'14px'}
            fontWeight={'600'}
            lineHeight={'20px'}
            onClick={openManageModal}
          >
            Add Clients
          </Button>
        </Box>

        <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
          <Thead mb="16px" display={'block'}>
            <Tr
              borderRadius={'10px'}
              border={'solid 0.5px #E2E2E2'}
              background={'#F6F6F6'}
              display="flex"
              justifyContent="space-between"
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Client
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Tokens Held
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                USD Value
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Profit/Loss
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Payouts
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                textAlign={'right'}
              >
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData?.map((data, index) => (
              <Tr
                key={index}
                borderRadius="36px"
                background="#F6F6F6"
                mb={(index + 1) % 5 === 0 ? '0px' : '20px'}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Td
                  border="none"
                  padding="14px"
                  flex="1"
                  onClick={() => navigate(`/SingleClient/${data.client.id}`)}
                >
                  <Flex alignItems={'center'} gap={'8px'}>
                    <Img
                      src={data.client.token_img}
                      alt=""
                      height={'36px'}
                      width={'36px'}
                      cursor={'pointer'}
                    />
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                      cursor={'pointer'}
                    >
                      {data.client.name}
                    </Text>
                  </Flex>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  {data.total_tokens}
                </Td>
                <Td
                  border="none"
                  padding="14px"
                  flex="1"
                  gap={'8px'}
                  display={'flex'}
                  flexDirection={'row'}
                >
                  <Box
                    width={'20px'}
                    height={'20px'}
                    padding={'0px 4px'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    borderRadius={'6px'}
                    border={'1px solid #0FBF95'}
                    background={'#0FBF95'}
                    color={'#FFF'}
                    display={'flex'}
                  >
                    <Text
                      fontSize={'12px'}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                      fontFamily="Montserrat"
                    >
                      $
                    </Text>
                  </Box>
                  {(data.total_tokens * data.client.token_price).toFixed(2)}
                </Td>
                <Td border="none" padding="14px" flex="1">
                  <Text>${data.profit}</Text>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  <Flex flexDirection={'column'}>
                    <Text
                      color={'#8C8C8C'}
                      fontSize={'12px'}
                      fontWeight={'600'}
                      lineHeight={'20px'}
                    >
                      {data.paid_payouts} of {data.total_payouts}
                    </Text>
                    <Progress
                      width={'40%'}
                      value={(data.paid_payouts / data.total_payouts) * 100}
                      height={'6px'}
                      borderRadius={'100px'}
                      background="rgba(226, 226, 226, 1)"
                      sx={{
                        '& > div': {
                          background: '#0FBF95',
                        },
                      }}
                    />
                  </Flex>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  <Flex justifyContent={'flex-end'} gap={'4px'} width={'100%'}>
                    <Text
                      padding={'10px 14px'}
                      borderRadius={'12px'}
                      border={'0.5px solid #E2E2E2'}
                      background={'#FFF'}
                      fontSize={'12px'}
                      fontWeight={'500'}
                      onClick={() => {
                        onEditClick(data.client);
                      }}
                      cursor={'pointer'}
                    >
                      Edit
                    </Text>
                    <Box
                      padding={'10px 14px'}
                      borderRadius={'12px'}
                      border={'0.5px solid #E2E2E2'}
                      background={'#FFF'}
                      onClick={() => {
                        setClientToDelete(data.client);
                        onOpen();
                      }}
                      cursor={'pointer'}
                    >
                      <Img
                        src={deleteicon}
                        alt=""
                        height={'18px'}
                        width={'18px'}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Table variant="unstyled" display={['table', 'table', 'table', 'none']}>
          <Thead mb="16px" display={'block'}>
            <Tr
              borderRadius={'10px'}
              border={'solid 0.5px #E2E2E2'}
              background={'#F6F6F6'}
              display="flex"
              justifyContent="space-between"
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                position="relative"
              >
                Client
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '15%',
                    bottom: '15%',
                    width: '1px',
                    background: '#E2E2E2',
                  }}
                />
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                textAlign={'end'}
                whiteSpace={'nowrap'}
              >
                Tokens Held
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData?.map((data, index) => (
              <Tr
                key={index}
                borderRadius="36px"
                background="#F6F6F6"
                mb={(index + 1) % 5 === 0 ? '0px' : '20px'}
                display="flex"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="20px 20px 14px 20px"
                  cursor="pointer"
                >
                  <Flex alignItems={'center'} gap={'8px'}>
                    <Image
                      src={data.client.token_img}
                      alt=""
                      height={'36px'}
                      width={'36px'}
                      onClick={() =>
                        navigate(`/SingleClient/${data.client.id}`)
                      }
                    />
                    <Text
                      fontSize={'13px'}
                      fontWeight={'500'}
                      lineHeight={'20px'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.client.name}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={2} padding={'0px 12px'}>
                    <Box display={'flex'} justifyContent={'flex-start'}>
                      <Text
                        fontSize={'12px'}
                        fontWeight={'500'}
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                        textAlign={'right'}
                      >
                        {data.total_tokens}
                      </Text>
                    </Box>
                    <ChevronDownIcon
                      color="#8C8C8C"
                      boxSize={6}
                      transform={
                        expandedRowIndex === index ? 'rotate(180deg)' : 'unset'
                      }
                      transition="0.2s all"
                      onClick={() => openRow(index)}
                    />
                  </Flex>
                </Box>

                <Collapse in={expandedRowIndex === index} animateOpacity>
                  <Box padding="0px 50px 14px 20px">
                    <Flex justifyContent="space-between" mb="10px">
                      <Text
                        color="#8C8C8C"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                      >
                        USD Value
                      </Text>
                      <Flex gap={'8px'} alignItems={'center'}>
                        <Box
                          width={'20px'}
                          height={'20px'}
                          padding={'0px 4px'}
                          flexDirection={'column'}
                          alignItems={'center'}
                          borderRadius={'6px'}
                          border={'1px solid #0FBF95'}
                          background={'#0FBF95'}
                          color={'#FFF'}
                          display={'flex'}
                        >
                          <Text
                            fontSize={'12px'}
                            fontWeight={'700'}
                            lineHeight={'20px'}
                            fontFamily="Montserrat"
                          >
                            %
                          </Text>
                        </Box>
                        <Text
                          fontSize={'13px'}
                          fontWeight={'500'}
                          lineHeight={'20px'}
                          fontFamily="Montserrat"
                          fontStyle={'normal'}
                        >
                          {}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text
                        color="#8C8C8C"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                      >
                        Profit/Loss
                      </Text>
                      <Text
                        color={'#8C8C8C'}
                        fontSize={'13px'}
                        fontWeight={'500'}
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                        fontStyle={'normal'}
                      >
                        ${data.profit}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text
                        color="#8C8C8C"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                      >
                        Payouts
                      </Text>
                      <Flex flexDirection={'column'} alignItems="flex-end">
                        <Text
                          color={'#8C8C8C'}
                          fontSize={'12px'}
                          fontWeight={'600'}
                          lineHeight={'20px'}
                        >
                          {data.paid_payouts} of {data.total_payouts}
                        </Text>
                        <Progress
                          width={'50px'}
                          value={(data.paid_payouts / data.total_payouts) * 100}
                          height={'6px'}
                          borderRadius={'100px'}
                          background="rgba(226, 226, 226, 1)"
                          sx={{
                            '& > div': {
                              background: '#0FBF95',
                            },
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                  <Td
                    border="none"
                    padding="14px"
                    flex="1"
                    display={'flex'}
                    justifyContent={'flex-end'}
                    borderTop={'1px solid #E2E2E2'}
                  >
                    <Flex
                      justifyContent={'flex-end'}
                      gap={'4px'}
                      width={'100%'}
                    >
                      <Text
                        padding={'10px 14px'}
                        borderRadius={'12px'}
                        border={'0.5px solid #E2E2E2'}
                        background={'#FFF'}
                        fontSize={'12px'}
                        fontWeight={'500'}
                        onClick={() => {
                          onEditClick(data.client);
                        }}
                      >
                        Edit
                      </Text>
                      <Box
                        padding={'10px 14px'}
                        borderRadius={'12px'}
                        border={'0.5px solid #E2E2E2'}
                        background={'#FFF'}
                        onClick={() => {
                          setClientToDelete(data.client);
                          onOpen();
                        }}
                      >
                        <Img
                          src={deleteicon}
                          alt=""
                          height={'18px'}
                          width={'18px'}
                        />
                      </Box>
                    </Flex>
                  </Td>
                </Collapse>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <HStack
        spacing="6px"
        padding={'16px'}
        borderRadius={'44px'}
        border={'1px solid #EFEFEF'}
        background={'#FFF'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
        width={'fit-content'}
        alignSelf={'center'}
        marginTop={'20px'}
      >
        <IconButton
          aria-label="Previous page"
          icon={<ChevronLeftIcon height={'24px'} width={'24px'} />}
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage <= 1}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        />

        {renderPageNumbers()}

        <IconButton
          aria-label="Next page"
          icon={<ChevronRightIcon height={'24px'} width={'24px'} />}
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage >= totalPages}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        />
      </HStack>
    </>
  );
};

export default ClientOverviewTab;
