import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Client from './components/clients/Clients';
import Overlay from './components/common/Overlay';
import UnauthorizedOverlay from './components/common/UnauthorizedOverlay';
import Investment from './components/investments/Investment';
import LiquadationTracker from './components/LiquadationTrackerNew/LiquadationTracker';
import LiquidationTracker from './components/LiquidationTracker/LiquidationTracker';
import Login from './components/login/Login';
import Main from './components/main/Main';
import Signup from './components/signup/Signup';
import ClientOverview from './components/SingleClient/ClientOverview';
import SingleClient from './components/SingleClient/SingleClient';
import UpCommingPayOut from './components/UpComminPayOut/UpCommingPayOut';
import Payouts from './payouts/Payouts';
import { AppStoreState, useAppStore } from './store';
import { CustomTheme } from './theme/theme';

function AuthenticatedRoutes() {
  return (
    <ChakraProvider theme={CustomTheme}>
      <BrowserRouter>
        <Overlay>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/investments" element={<Investment />} />
            <Route path="/clients" element={<Client />} />
            <Route path="/payouts" element={<Payouts />} />
            <Route path="/liquidation" element={<LiquidationTracker />} />
            <Route path="/*" element={<Navigate to="/" />} />
            <Route path="upcoming-payouts" element={<UpCommingPayOut />} />
            <Route path="/SingleClient/:clientId" element={<SingleClient />} />
            <Route path="ClientOverview" element={<ClientOverview />} />
            <Route
              path="/liquidation-tracker"
              element={<LiquadationTracker />}
            />
          </Routes>
        </Overlay>
      </BrowserRouter>
    </ChakraProvider>
  );
}

function UnAuthenticatedRoutes() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <UnauthorizedOverlay>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/*" element={<Navigate to="/login" />} />
          </Routes>
        </UnauthorizedOverlay>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const App = () => {
  const loginApiResponse = useAppStore(
    (state: AppStoreState) => state.loginApiResponse,
  );
  const isLoggedIn = loginApiResponse?.data?.access_token ? true : false;
  if (isLoggedIn) {
    return <AuthenticatedRoutes />;
  }
  return <UnAuthenticatedRoutes />;
};

export default App;
