import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

import ClaimedTokensTable from './ClaimedTokensTable';
import LiquidationTable from './LiquidationTable';
import MyTokens from './MyTokens';
import Switcher from './Switcher';

type Tab = 'Liquidations tracker' | 'Claimed-tokens';
const LiquidationTracker: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tab>('Liquidations tracker');

  const handleSwitch = (tab: Tab) => {
    setActiveTab(tab);
  };
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        marginTop={['14px', '14px', '14px', '20px']}
        marginBottom={'20px'}
        width={'100%'}
      >
        <Text
          paddingLeft={['12px', '16px', '20px']}
          paddingRight={['12px', '16px', '20px']}
          fontSize={['20px', '22px', '24px', '36px']}
          fontWeight={700}
          fontFamily="Montserrat"
          textColor={'#1A1A1A'}
        >
          Welcome to your liquidation tracker 💰
        </Text>
      </Box>
      <Flex
        flexDirection={['column', 'column', 'row']}
        gap={['36px', '36px', '18px']}
        paddingLeft={['0px', '0px', '20px']}
        paddingRight={['0px', '0px', '20px']}
        paddingBottom={['0px', '0px', '18px']}
        maxWidth={'100%'}
        alignItems={'flex-start'}
      >
        <Flex
          flexDirection={'column'}
          padding={['12px', '22px']}
          gap={'24px'}
          background={'#FFF'}
          borderRadius={'36px'}
          width={'100%'}
          maxWidth={['auto', 'auto', 'auto', '380px']}
          boxShadow={
            '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
          }
          border={'1px solid #F6F6F6'}
        >
          <Box>
            <Flex
              flexDirection={'column'}
              gap={'10px'}
              background={'#F2F2F2'}
              padding={'14px'}
              borderRadius={'18px'}
            >
              <Text
                fontSize={'16px'}
                fontWeight={'600'}
                fontFamily="Montserrat"
              >
                Balance
              </Text>
              <Flex flexDirection={'row'} gap={'6px'}>
                <Text
                  fontSize={'36px'}
                  fontFamily="Montserrat"
                  fontWeight={700}
                  lineHeight={'normal'}
                >
                  100
                  {/* <Animation value={totalClaimAmount?.toFixed(2)} /> */}
                </Text>
                <Text
                  pt={'3px'}
                  color={'rgba(140, 140, 140, 1)'}
                  fontSize={'14px'}
                  fontWeight={500}
                  fontFamily="Montserrat"
                  lineHeight={'20px'}
                >
                  USD
                </Text>
              </Flex>
            </Flex>
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <MyTokens />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} flex={'1'}>
        <Flex
          justifyContent="flex-start"
          background={'rgba(255, 255, 255, 1)'}
          borderTopRadius={'44px'}
          boxShadow={
            '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
          }
          border={'rgba(255, 255, 255, 1)'}
          padding={[
            '16px 16px 0px 16px',
            '16px 16px 0px 16px',
            '16px 16px 0px 16px',
            '22px 22px 0px 22px',
          ]}
        >
          <Switcher onSwitch={handleSwitch} activeTab={activeTab} />
        </Flex>
        {activeTab === 'Liquidations tracker' ? (
          <LiquidationTable />
        ) : (
          <ClaimedTokensTable />
        )}
      </Flex>
    </Box>
  );
};

export default LiquidationTracker;
