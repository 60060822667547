import {
  useToast,
  Spinner,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Box,
  Tbody,
  Text,
  Td,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getPayouts } from '../../api/InvestorApis/apis';
import { useAppStore, AppStoreState } from '../../store';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons';
import { getInvestments } from '../../api/InvestorApis/investments';
import Countdown from '../Countdowm';
import ClaimTokenModal from '../claimToken/ClaimTokenModal';
import { useNavigate } from 'react-router-dom';
import ManageInvestmentModal from '../investments/ManageInvestmentModal';

export default function Main() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [payloadId, setPayloadId] = useState('');
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const payoutList = useAppStore((state: AppStoreState) => state.payoutList);
  const setPayoutList = useAppStore(
    (state: AppStoreState) => state.setPayoutList,
  );
  const investmentsList = useAppStore(
    (state: AppStoreState) => state.investmentsList,
  );
  const setInvestmentsList = useAppStore(
    (state: AppStoreState) => state.setInvestmentsList,
  );
  const investmentItemsPerPage =
    investmentsList &&
    investmentsList?.results?.length > 0 &&
    investmentsList?.results?.length;
  const investmentTotalPages =
    investmentItemsPerPage && investmentsList && investmentsList?.total_pages;
  const payoutItemsPerPage =
    payoutList &&
    payoutList?.results?.length > 0 &&
    payoutList?.results?.length;
  const payoutTotalPages =
    payoutItemsPerPage && payoutList && payoutList?.total_pages;
  const {
    isOpen: manageModalOpen,
    onOpen: openManageModal,
    onClose: closeManageModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const fetchPayouts = async (page: number) => {
    try {
      setLoading(true);
      const res = await getPayouts(true,page);
      if (res) {
        setPayoutList(res);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching payouts',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchInvestments = async (page: number) => {
    try {
      setLoading(true);
      const res = await getInvestments(page);
      if (res) {
        setInvestmentsList(res);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching investments',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchPayouts(1);
    fetchInvestments(1);
  }, []);

  const handlePayoutPrevPage = () => {
    payoutList?.current_page && fetchPayouts(payoutList?.current_page - 1);
  };

  const handlePayoutNextPage = () => {
    payoutList?.current_page && fetchPayouts(payoutList?.current_page + 1);
  };

  const handleInvestmentPrevPage = () => {
    investmentsList?.current_page &&
      fetchInvestments(investmentsList?.current_page - 1);
  };

  const handleInvestmentNextPage = () => {
    investmentsList?.current_page &&
      fetchInvestments(investmentsList?.current_page + 1);
  };

  const [expandedRowIndex, setExpandedRowIndex] = useState<number>();

  const openRow = (index: number) => {
    if (index === expandedRowIndex) {
      setExpandedRowIndex(undefined);
    } else {
      setExpandedRowIndex(index);
    }
  };

  return (
    <>
      <ManageInvestmentModal
        isOpen={manageModalOpen}
        onClose={() => {
          closeManageModal();
        }}
        onAdded={() => {
          closeManageModal();
          fetchInvestments(1);
        }}
        investmentToEdit={null}
      />
      <ClaimTokenModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setPayloadId('');
        }}
        payoutId={payloadId}
      />
      {loading ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Box>
      ) : (
        <Box width="100%">
          <Box pb="20px">
            <Text
              fontFamily="Montserrat"
              fontWeight="700"
              fontSize={['16px', '18px', '24px', '36px', '36px']}
              color="#1A1A1A"
              marginLeft={['20px', '20px', 'unset']}
            >
              Welcome to your Dashboard, User! 👋
            </Text>
          </Box>
          <Box
            width="100%"
            gap="20px"
            display="flex"
            flexDirection={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
          >
            <Box width={['100%', '100%', '100%', '100%', '60%']}>
              <Box
                bg="white"
                borderRadius="20px"
                border="1px solid #E2E2E2"
                padding="16px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text
                    fontFamily="Montserrat"
                    fontSize={['14px', '18px', '24px']}
                    fontWeight="600"
                    color="#1A1A1A"
                  >
                    Upcoming payouts
                  </Text>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="600"
                    color="#0FBF95"
                    cursor="pointer"
                    onClick={() => navigate('/payouts')}
                  >
                    All upcoming
                  </Text>
                </Box>

                <TableContainer marginTop="10px">
                  <Table variant="simple" display={['none', 'none', 'table']}>
                    <Thead>
                      <Tr borderRadius="10px" background="#F2F2F2">
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                          borderRadius="10px 0px 0px 10px"
                        >
                          Next payout
                        </Th>
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                        >
                          Client
                        </Th>
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                        >
                          Claim Amount
                        </Th>
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                        >
                          Tokens in dollars
                        </Th>
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                          borderRadius="0px 10px 10px 0px"
                        >
                          Payout part
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {payoutList?.results &&
                        payoutList?.results?.length > 0 &&
                        payoutList?.results.map((payout) => (
                          <Tr key={payout.id}>
                            <Td
                              fontFamily="Montserrat"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              <Countdown
                                endDateString={payout.date}
                                paid={payout.paid}
                                openClaimModal={() => {
                                  setPayloadId(payout?.id.toString());
                                  onOpen();
                                }}
                              />
                            </Td>
                            <Td
                              fontFamily="Montserrat"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              {payout.client?.name}
                            </Td>
                            <Td
                              fontFamily="Montserrat"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              {payout.amount}
                            </Td>
                            <Td
                              fontFamily="Montserrat"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              {payout.investment.token_price
                                ? payout.investment.token_price
                                : '-'}
                            </Td>
                            <Td
                              fontFamily="Montserrat"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              {payout.payout_part} of {payout.total_payouts}
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>

                  <Table variant="simple" display={['table', 'table', 'none']}>
                    <Thead>
                      <Tr
                        borderRadius="10px"
                        borderBottom="10px solid white"
                        background="#F2F2F2"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                          padding="0.75rem 1.5rem"
                        >
                          <Text
                            fontFamily="Montserrat"
                            fontSize="13px"
                            fontWeight="700"
                            color="#1A1A1A"
                            textTransform="capitalize"
                          >
                            Claim Amount
                          </Text>
                          <Text
                            fontFamily="Montserrat"
                            fontSize="13px"
                            fontWeight="700"
                            color="#1A1A1A"
                            textTransform="capitalize"
                          >
                            Client
                          </Text>
                        </Box>
                      </Tr>
                    </Thead>
                    <Tbody mt="10px">
                      {payoutList?.results &&
                        payoutList?.results?.length > 0 &&
                        payoutList?.results.map((payout, index) => (
                          <Tr
                            key={payout.id}
                            borderBottom={
                              expandedRowIndex !== index
                                ? '1px solid #E2E2E2'
                                : '1px solid white'
                            }
                          >
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="column"
                              gap={expandedRowIndex === index ? 4 : 0}
                              padding="16px 12px"
                              borderRadius="10px"
                              bg={
                                expandedRowIndex === index ? '#F2F2F2' : 'white'
                              }
                              borderBottom={
                                expandedRowIndex === index
                                  ? '1px solid #E2E2E2'
                                  : '1px solid white'
                              }
                              transition="0.2s all"
                              cursor="pointer"
                              onClick={() => openRow(index)}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={2}
                                  fontFamily="Montserrat"
                                  fontSize="13px"
                                  fontWeight="500"
                                >
                                  {payout.amount}
                                </Box>
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Text
                                    fontFamily="Montserrat"
                                    fontSize="13px"
                                    fontWeight="500"
                                    textAlign="end"
                                  >
                                    {payout.client.name}
                                  </Text>
                                  <ChevronDownIcon
                                    color="#8C8C8C"
                                    boxSize={6}
                                    transform={
                                      expandedRowIndex === index
                                        ? 'rotate(180deg)'
                                        : 'unset'
                                    }
                                    transition="0.2s all"
                                  />
                                </Box>
                              </Box>

                              <Collapse
                                in={expandedRowIndex === index}
                                animateOpacity
                              >
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={2}
                                  alignItems="center"
                                  width="100%"
                                >
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                  >
                                    <Text
                                      fontFamily="Montserrat"
                                      fontSize="13px"
                                      fontWeight="500"
                                      color="#8C8C8C"
                                    >
                                      Tokens in dollars
                                    </Text>
                                    <Text
                                      fontFamily="Montserrat"
                                      fontSize="13px"
                                      fontWeight="500"
                                      color="#8C8C8C"
                                    >
                                      {payout.investment.token_price
                                        ? payout.investment.token_price
                                        : '-'}
                                    </Text>
                                  </Box>

                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                  >
                                    <Text
                                      fontFamily="Montserrat"
                                      fontSize="13px"
                                      fontWeight="500"
                                      color="#8C8C8C"
                                    >
                                      Client
                                    </Text>
                                    <Text
                                      fontFamily="Montserrat"
                                      fontSize="13px"
                                      fontWeight="500"
                                      color="#8C8C8C"
                                    >
                                      {payout.client.name
                                        ? payout.client.name
                                        : 'null'}
                                    </Text>
                                  </Box>

                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                  >
                                    <Text
                                      fontFamily="Montserrat"
                                      fontSize="13px"
                                      fontWeight="500"
                                      color="#8C8C8C"
                                    >
                                      Payout
                                    </Text>
                                    <Text
                                      fontFamily="Montserrat"
                                      fontSize="13px"
                                      fontWeight="500"
                                      color="#8C8C8C"
                                    >
                                      {payout.amount ? payout.amount : null}
                                    </Text>
                                  </Box>
                                </Box>
                              </Collapse>
                              <Box
                                pt="12px"
                                borderTop={
                                  expandedRowIndex === index
                                    ? '1px solid  #E2E2E2'
                                    : 'none'
                                }
                              >
                                <Countdown
                                  endDateString={payout.date}
                                  paid={payout.paid}
                                  openClaimModal={() => {
                                    setPayloadId(payout?.id.toString());
                                    onOpen();
                                  }}
                                />
                              </Box>
                            </Box>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="20px"
                  padding={['unset', 'unset', '0px 20px']}
                >
                  <Button
                    fontFamily="Montserrat"
                    fontSize="14px"
                    variant="link"
                    leftIcon={<ArrowLeftIcon />}
                    onClick={handlePayoutPrevPage}
                    isDisabled={payoutList?.current_page === 1}
                  >
                    Previous
                  </Button>
                  <Text
                    fontFamily="Montserrat"
                    fontSize={['10px', '10px', '14px']}
                  >{`Page ${payoutList?.current_page} of ${payoutTotalPages}`}</Text>
                  <Button
                    fontFamily="Montserrat"
                    fontSize="14px"
                    variant="link"
                    rightIcon={<ArrowRightIcon />}
                    onClick={handlePayoutNextPage}
                    isDisabled={payoutList?.current_page === payoutTotalPages}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box width={['100%', '100%', '100%', '100%', '40%']}>
              <Box
                bg="white"
                borderRadius="20px"
                border="1px solid #E2E2E2"
                padding="16px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text
                    fontFamily="Montserrat"
                    fontSize={['14px', '18px', '24px']}
                    fontWeight="600"
                    color="#1A1A1A"
                  >
                    Investment Tracker
                  </Text>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="600"
                    color="#0FBF95"
                    cursor="pointer"
                    onClick={() => {
                      openManageModal();
                    }}
                  >
                    Add Investment
                  </Text>
                </Box>

                <TableContainer marginTop="10px">
                  <Table variant="simple">
                    <Thead>
                      <Tr borderRadius="10px" background="#F2F2F2">
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                          borderRadius="10px 0px 0px 10px"
                        >
                          Client
                        </Th>
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                        >
                          Amount Claimed
                        </Th>
                        <Th
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="700"
                          color="#1A1A1A"
                          textTransform="capitalize"
                          borderRadius="0px 10px 10px 0px"
                        >
                          Invested
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {investmentsList?.results &&
                        investmentsList?.results?.length > 0 &&
                        investmentsList?.results.map((investment: any) => (
                          <Tr key={investment.id}>
                            <Td
                              fontFamily="Montserrat"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              {investment.client?.name}
                            </Td>
                            <Td
                              fontFamily="Montserrat"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              {investment.total_amount}
                            </Td>
                            <Td
                              fontFamily="Montserrat"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              ${investment?.total_amount}
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="20px"
                  padding={['unset', 'unset', '0px 20px']}
                >
                  <Button
                    fontFamily="Montserrat"
                    fontSize="14px"
                    variant="link"
                    leftIcon={<ArrowLeftIcon />}
                    onClick={handleInvestmentPrevPage}
                    isDisabled={investmentsList?.current_page === 1}
                  >
                    Previous
                  </Button>
                  <Text
                    fontFamily="Montserrat"
                    fontSize={['10px', '10px', '14px']}
                  >{`Page ${investmentsList?.current_page} of ${investmentTotalPages}`}</Text>
                  <Button
                    fontFamily="Montserrat"
                    fontSize="14px"
                    variant="link"
                    rightIcon={<ArrowRightIcon />}
                    onClick={handleInvestmentNextPage}
                    isDisabled={
                      investmentsList?.current_page === investmentTotalPages
                    }
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
