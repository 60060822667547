import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';

import leftDisabled from '../../assets/keyboard_arrow_left.png';
import left from '../../assets/keyboard_arrow_left1.png';
import rightDisabled from '../../assets/keyboard_arrow_right.png';
import right from '../../assets/keyboard_arrow_right1.png';
import arrowdown from '../../assets/trending_down.png';
import arrowup from '../../assets/trending_up.png';

interface TokenItemProps extends FlexProps {
  client: string;
  amount: number;
  price: number;
  dollar_value: number;
}

const TokenItem: React.FC<TokenItemProps> = ({
  client,
  price,
  amount,
  dollar_value,
  ...rest
}) => {
  const valueColor = price < 0 ? 'red' : 'green';
  const formattedValue = price < 0 ? `-$${Math.abs(price)}` : `$${price}`;
  const img =
    price < 0 ? (
      <Image src={arrowdown} width={'20px'} height={'20px'} />
    ) : (
      <Image src={arrowup} width={'20px'} height={'20px'} />
    );

  return (
    <Flex
      key={client}
      flexDirection={'row'}
      flexGrow={'1'}
      alignItems={'center'}
      justifyContent={'space-between'}
      paddingBottom={'16px'}
      borderWidth={'90%'}
      {...rest}
    >
      <Flex gap={'12px'} alignItems={'center'} justifyContent={'center'}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'100px'}
          background={'#E6F9F4'}
          gap={'10px'}
          width={'32px'}
          height={'32px'}
        >
          <Text
            color={'#0FBF95'}
            fontSize={'16px'}
            fontWeight={'600'}
            fontFamily="Montserrat"
          >
            {client[0]}
          </Text>
        </Box>
        <Flex
          flexDirection={'column'}
          gap={'6px'}
          justifyContent={'center'}
          alignItems={'flex-start'}
        >
          <Text
            fontSize={'16px'}
            fontWeight={'600'}
            fontFamily="Montserrat"
            color={'#1A1A1A'}
          >
            {client}
          </Text>
          <Text
            color={'#8C8C8C'}
            fontFamily="Montserrat"
            fontSize={'15px'}
            fontWeight={'500'}
            lineHeight={'20px'}
          >
            ${(amount * price).toFixed(2)}
          </Text>
        </Flex>
      </Flex>
      <Flex
        gap={'6px'}
        alignItems={'flex-end'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <Text
          color={'#1A1A1A'}
          fontSize={'15px'}
          fontWeight={'500'}
          fontFamily="Montserrat"
        >
          {amount}
        </Text>
        <Flex gap={'4px'} alignItems={'center'}>
          <Text
            color={valueColor}
            fontSize={'14px'}
            fontWeight={'600'}
            fontFamily="Montserrat"
            lineHeight={'20px'}
          >
            {formattedValue}
          </Text>
          {img}
        </Flex>
      </Flex>
    </Flex>
  );
};

const ITEMS_PER_PAGE = 4;

// Dummy data
const dummyData = [
  {
    client: 'Apple Inc',
    amount: 100,
    price: -150.25,
    dollar_value: 15025,
    is_liquidated: false,
  },
  {
    client: 'Microsoft',
    amount: 75,
    price: 300.5,
    dollar_value: 22537.5,
    is_liquidated: false,
  },
  {
    client: 'Amazon',
    amount: 50,
    price: 3300.75,
    dollar_value: 165037.5,
    is_liquidated: false,
  },
  {
    client: 'Google',
    amount: 25,
    price: 2750.0,
    dollar_value: 68750,
    is_liquidated: false,
  },
  {
    client: 'Facebook',
    amount: 60,
    price: 325.75,
    dollar_value: 19545,
    is_liquidated: false,
  },
  {
    client: 'Tesla',
    amount: 40,
    price: 700.0,
    dollar_value: 28000,
    is_liquidated: false,
  },
];

const MyTokens: React.FC = () => {
  const [paginatedData, setPaginatedData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const data = dummyData
      .filter((item) => item.is_liquidated == false)
      .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);
    setPaginatedData(data);
  }, [currentPage]);

  const totalPages = useMemo(() => {
    const nonLiquidatedData = dummyData.filter(
      (item) => item.is_liquidated == false,
    );
    return Math.ceil(nonLiquidatedData?.length / ITEMS_PER_PAGE || 1);
  }, []);

  return (
    <>
      <Text
        fontSize="24px"
        fontWeight={600}
        fontFamily="Montserrat"
        marginBottom={'24px'}
      >
        My Tokens
      </Text>
      <VStack gap={'16px'} spacing={0} align="stretch">
        {paginatedData?.map((item: typeof dummyData[0], index: number) => (
          <TokenItem
            key={item.client}
            borderBottom={
              index + 1 === paginatedData.length ? 'none' : '1px solid #F2F2F2'
            }
            client={item.client || ''}
            amount={item.amount || 0}
            dollar_value={item.dollar_value || 0}
            price={item.price || 0}
          />
        ))}
        <Flex justifyContent="flex-end" paddingTop={'12px'}>
          <HStack spacing="4px">
            <img
              width={'34px'}
              height={'34px'}
              src={currentPage === 1 ? leftDisabled : left}
              alt="trending up"
              onClick={() => {
                if (currentPage !== 1) {
                  setCurrentPage(currentPage - 1);
                }
              }}
            />
            <img
              width={'34px'}
              height={'34px'}
              src={currentPage >= totalPages ? rightDisabled : right}
              alt="trending up"
              onClick={() => {
                if (currentPage < totalPages) {
                  setCurrentPage(currentPage + 1);
                }
              }}
            />
          </HStack>
        </Flex>
      </VStack>
    </>
  );
};

export default MyTokens;
