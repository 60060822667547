import { Box, Flex, Image, Img, Text, useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSingleClientData } from '../../api/InvestorApis/client';
import backicon from '../../assets/arrow_back_icon.png';
import coin from '../../assets/Fantom.png';
import trendup from '../../assets/trending_up.png';
import AddInvestment from './AddInvesment';
import ClaimComp from './ClaimComp';
import LiquadationTable from './LiquadationTable';
import LiquidationsComponent from './LiquidationsComponent';
import PayoutComp from './PayoutComp';
import Switcher from './Switcher';

type Tab = 'Payouts' | 'Claimed-tokens' | 'Liquidations';
const SingleClient: React.FC = () => {
  const { clientId } = useParams();
  const [activeTab, setActiveTab] = useState<Tab>('Payouts');
  const toast = useToast();
  const {
    data: singleClientData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['singleClient'],
    queryFn: () => getSingleClientData(parseInt(clientId ?? '1'), 1, 1),
  });

  if (isLoading) return <p>Loading...</p>;
  if (error)
    toast({
      title: 'Something went wrong while fetching payouts',
      description: error.message,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  if (!isLoading && !singleClientData?.investment?.length)
    return <AddInvestment clientId={clientId} />;
  const paidPayouts = singleClientData?.payout.filter((item) => item.paid);
  const unpaidPayouts = singleClientData?.payout.filter((item) => !item.paid);
  const totalPaidPayoutsAmount = paidPayouts?.reduce(
    (prev, item) => prev + parseInt(item.amount),
    0,
  );
  const tokenPrice = parseFloat(
    singleClientData?.investment[0].client.token_price,
  );
  const handleSwitch = (tab: Tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Flex
        flexDirection={'column'}
        marginTop={'36px'}
        marginBottom={'24px'}
        gap={['14px', '6px']}
        padding={['0px ', '0px ', '0px 20px']}
      >
        <Box
          padding={'8px 12px'}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          borderRadius={'14px'}
          border={'1px solid #E2E2E2'}
          background={'#FFF'}
          width={'fit-content'}
          onClick={() => window.history.back()}
          cursor={'pointer'}
        >
          <Image src={backicon} alt=" " width={'24px'} height={'24px'} />
          <Text
            color={'#1A1A1A'}
            textAlign={'center'}
            fontSize={'18px'}
            fontWeight={'500'}
            lineHeight={'normal'}
            fontStyle={'normal'}
            fontFamily="Montserrat"
            onClick={() => window.history.back()}
            cursor={'pointer'}
          >
            Back
          </Text>
        </Box>
        <Text
          color={'#1A1A1A'}
          textAlign={'center'}
          fontSize={['24px', '36px']}
          fontWeight={'700'}
          lineHeight={'normal'}
          fontStyle={'normal'}
          width={'fit-content'}
          fontFamily="Montserrat"
        >
          Good afternoon, Bart! 👋
        </Text>
      </Flex>
      <Flex
        flexDirection={'column'}
        padding={['0px', '0px', '0px 20px']}
        gap={['20px']}
      >
        <Flex
          gap={'20px'}
          flexDirection={['column', 'column', 'column', 'row']}
        >
          <Flex
            gap={'20px'}
            maxWidth={['100%', '100%', '100%', '590px']}
            flex="1"
            display={['none', 'none', 'none', 'flex']}
          >
            <Flex
              padding={['22px']}
              width={'100%'}
              flexDirection={'column'}
              gap={'16px'}
              borderRadius={'44px'}
              border={'1px solid #F6F6F6'}
              background={'#FFF'}
              boxShadow={
                '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
              }
            >
              <Box
                display={'flex'}
                width={'100%'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} gap={'10px'}>
                  <Img
                    src={singleClientData?.investment[0].client.token_img}
                    alt=""
                    width={'44px'}
                    height={'44px'}
                    borderRadius={'50px'}
                  />
                  <Text
                    color={'#1A1A1A'}
                    fontWeight={'700'}
                    fontSize={'24px'}
                    fontFamily="Montserrat"
                  >
                    {singleClientData?.investment[0]?.client.name}
                  </Text>
                </Box>
              </Box>
              {/* here */}
              <Flex gap={'16px'}>
                <Flex
                  padding={'16px'}
                  gap={'8px'}
                  borderRadius={'24px'}
                  background={'#F2F2F2'}
                  flexDir={'column'}
                  width={'100%'}
                  height={'172.5px'}
                >
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      color={'#1A1A1A'}
                      fontFamily="Montserrat"
                    >
                      Token Balance
                    </Text>
                    <Box
                      padding={'6px 12px'}
                      alignItems={'center'}
                      background={'#FFF'}
                      borderRadius={'16px'}
                      boxShadow={'0px 1px 3px 0px rgba(0, 0, 0, 0.05)'}
                      gap={'8px'}
                    >
                      <Text
                        color={'#8C8C8C'}
                        fontSize={'15px'}
                        fontWeight={'500'}
                        lineHeight={'20px'}
                        fontStyle={'normal'}
                        fontFamily="Montserrat"
                      >
                        $ {tokenPrice.toFixed(2)}
                      </Text>
                    </Box>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-start'}>
                    <Text
                      color={'#1A1A1A'}
                      fontSize={'36px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontStyle={'normal'}
                      fontFamily="Montserrat"
                      textAlign={'center'}
                    >
                      {totalPaidPayoutsAmount}
                    </Text>
                  </Box>
                </Flex>
                <Flex
                  padding={'16px'}
                  gap={'8px'}
                  borderRadius={'24px'}
                  background={'#F2F2F2'}
                  flexDir={'column'}
                  width={'100%'}
                >
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      color={'#1A1A1A'}
                      fontFamily="Montserrat"
                    >
                      Token value
                    </Text>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-start'}>
                    <Text
                      color={'#1A1A1A'}
                      fontSize={'36px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontStyle={'normal'}
                      fontFamily="Montserrat"
                      textAlign={'center'}
                    >
                      {(tokenPrice * (totalPaidPayoutsAmount ?? 0)).toFixed(2)}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
              <Flex gap={'16px'}>
                <Flex
                  padding={'16px'}
                  gap={'8px'}
                  borderRadius={'24px'}
                  background={'#F2F2F2'}
                  flexDir={'column'}
                  width={'100%'}
                  height={'172.5px'}
                  flex={'1'}
                >
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      color={'#1A1A1A'}
                      fontFamily="Montserrat"
                    >
                      Payouts received
                    </Text>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-start'}>
                    <Text
                      textAlign={'center'}
                      fontSize={'36px'}
                      fontWeight={'600'}
                      fontFamily="Montserrat"
                      color={'#A6A6A6'}
                    >
                      <span style={{ color: '#0FBF95' }}>
                        {paidPayouts?.length}
                      </span>
                      /{singleClientData?.payout?.length ?? 0}
                    </Text>
                  </Box>
                </Flex>

                <Flex
                  padding={'16px'}
                  gap={'8px'}
                  borderRadius={'24px'}
                  background={'#F2F2F2'}
                  flexDir={'column'}
                  width={'100%'}
                  flex={'1'}
                >
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      color={'#1A1A1A'}
                      fontFamily="Montserrat"
                    >
                      Profit/Loss
                    </Text>
                    <Box
                      display={'flex'}
                      padding={'6px'}
                      alignItems={'center'}
                      borderRadius={'100px'}
                      background={'#FFF'}
                      boxShadow={'0px 1px 3px 0px rgba(0, 0, 0, 0.05)'}
                    >
                      <Img
                        src={trendup}
                        alt=""
                        width={'18px'}
                        height={'18px'}
                      />
                    </Box>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-start'}>
                    <Text
                      color={
                        (singleClientData?.liquidation?.reduce(
                          (prev, curr) => prev + curr.profit,
                          0,
                        ) ?? 0) < 0
                          ? '#FF0000'
                          : '#39D05D'
                      }
                      fontSize={'36px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontStyle={'normal'}
                      fontFamily="Montserrat"
                      textAlign={'center'}
                    >
                      {/* -${Math.abs(amount).toFixed(2)} */}
                      {/* ${Math.abs(amount).toFixed(2)} */}
                      {/* {Math.abs(
                        singleClientData?.liquidation?.reduce(
                          (prev, curr) => prev + curr.profit,
                          0,
                        ) ?? 0,
                      ).toFixed(2)} */}
                      {(() => {
                        const totalProfit =
                          singleClientData?.liquidation?.reduce(
                            (prev, curr) => prev + curr.profit,
                            0,
                          ) ?? 0;

                        return totalProfit < 0
                          ? `-$${Math.abs(totalProfit).toFixed(2)}`
                          : `$${totalProfit.toFixed(2)}`;
                      })()}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            display={['flex', 'flex', 'flex', 'none']}
            padding={'16px'}
            gap={'16px'}
            width={'100%'}
            flexDirection={'column'}
            borderRadius={'44px'}
            border={'1px solid #F6F6F6'}
            background={'#FFF'}
            boxShadow={
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
            }
          >
            <Box display={'flex'} gap={'10px'} alignItems={'center'}>
              <Img
                src={coin}
                alt=""
                width={'44px'}
                height={'44px'}
                borderRadius={'50px'}
              />
              <Text
                color={'#1A1A1A'}
                fontWeight={'700'}
                fontSize={['18px', '24px']}
                fontFamily="Montserrat"
              >
                {singleClientData?.investment[0]?.client.name}
              </Text>
            </Box>
            <Box
              display={'flex'}
              padding={'16px'}
              flexDirection={'column'}
              gap={'8px'}
              borderRadius={'14px'}
              background={'#F2F2F2'}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Text
                  fontSize={'16px'}
                  fontWeight={'600'}
                  color={'#1A1A1A'}
                  fontFamily="Montserrat"
                >
                  Token Balance
                </Text>
                <Box
                  padding={'6px 12px'}
                  alignItems={'center'}
                  background={'#FFF'}
                  borderRadius={'16px'}
                  boxShadow={'0px 1px 3px 0px rgba(0, 0, 0, 0.05)'}
                  gap={'8px'}
                >
                  <Text
                    color={'#8C8C8C'}
                    fontSize={'15px'}
                    fontWeight={'500'}
                    lineHeight={'20px'}
                    fontStyle={'normal'}
                    fontFamily="Montserrat"
                  >
                    $ {tokenPrice.toFixed(2)}
                  </Text>
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'flex-start'}>
                <Text
                  color={'#1A1A1A'}
                  fontSize={'36px'}
                  fontWeight={'600'}
                  lineHeight={'normal'}
                  fontStyle={'normal'}
                  fontFamily="Montserrat"
                  textAlign={'center'}
                >
                  ${totalPaidPayoutsAmount}
                </Text>
              </Box>
            </Box>
            <Flex flexDirection={'column'}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                padding={'16px'}
                borderBottom={'1px solid #F2F2F2'}
              >
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'#1A1A1A'}
                  fontFamily="Montserrat"
                >
                  Token value
                </Text>

                <Text
                  color={'#1A1A1A'}
                  fontSize={'14px'}
                  fontWeight={'600'}
                  lineHeight={'normal'}
                  fontStyle={'normal'}
                  fontFamily="Montserrat"
                  textAlign={'center'}
                >
                  {(tokenPrice * (totalPaidPayoutsAmount ?? 0)).toFixed(2)}
                </Text>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                padding={'16px'}
                borderBottom={'1px solid #F2F2F2'}
              >
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'#1A1A1A'}
                  fontFamily="Montserrat"
                >
                  Payouts received
                </Text>

                <Text
                  color={'#1A1A1A'}
                  fontSize={'14px'}
                  fontWeight={'600'}
                  lineHeight={'normal'}
                  fontStyle={'normal'}
                  fontFamily="Montserrat"
                  textAlign={'center'}
                >
                  <span style={{ color: '#0FBF95' }}>
                    {paidPayouts?.length}
                  </span>
                  /{singleClientData?.payout?.length ?? 0}
                </Text>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                padding={'16px'}
              >
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'#1A1A1A'}
                  fontFamily="Montserrat"
                >
                  Profit/Loss
                </Text>
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  <Text
                    color={'#0FBF95'}
                    fontSize={'14px'}
                    fontWeight={'600'}
                    lineHeight={'normal'}
                    fontStyle={'normal'}
                    fontFamily="Montserrat"
                    textAlign={'center'}
                  >
                    $
                    {singleClientData?.liquidation?.reduce((prev, curr) => {
                      return prev + curr.profit;
                    }, 0)}
                  </Text>
                  <Box
                    display={'flex'}
                    padding={'6px'}
                    alignItems={'center'}
                    borderRadius={'100px'}
                    background={'#F2F2F2'}
                    boxShadow={'0px 1px 3px 0px rgba(0, 0, 0, 0.05)'}
                  >
                    <Img src={trendup} alt="" width={'18px'} height={'18px'} />
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Flex>
          <LiquidationsComponent
            liquidateData={singleClientData?.liquidation ?? []}
          />
        </Flex>
        <Flex flexDirection={'column'} flex={'1'}>
          <Flex
            justifyContent="flex-start"
            background={'rgba(255, 255, 255, 1)'}
            borderTopRadius={'44px'}
            boxShadow={
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
            }
            border={'rgba(255, 255, 255, 1)'}
            padding={[
              '16px 16px 0px 16px',
              '16px 16px 0px 16px',
              '16px 16px 0px 16px',
              '22px 22px 0px 22px',
            ]}
          >
            <Switcher onSwitch={handleSwitch} activeTab={activeTab} />
          </Flex>
          {activeTab === 'Payouts' ? (
            <PayoutComp payouts={unpaidPayouts ?? []} />
          ) : activeTab === 'Claimed-tokens' ? (
            <ClaimComp claimedData={paidPayouts} />
          ) : (
            <LiquadationTable
              payouts={{ results: singleClientData?.payout ?? [] }}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default SingleClient;
